import { useEffect, useRef } from 'react';

import { NemoSpinner } from './styled';

export function SmallLoading() {
  return <NemoLoadingCentered />;
}

type NemoLoadingProps = {
  spinFrequency: number;
  colourSpinFrequency: number;
  radius: number;
  gapFraction: number;
  thickness: number;
  fadeIn: boolean;
  fadeInDuration?: number;
  fadeInDuration2?: number;
};

const loadingDefaults: NemoLoadingProps = {
  spinFrequency: 2,
  colourSpinFrequency: 1.5,
  radius: 40,
  gapFraction: 0.25,
  thickness: 4,
  fadeIn: true,
  fadeInDuration: 1,
  fadeInDuration2: 0.8,
};

export function NemoLoading(props: Partial<NemoLoadingProps> = {}) {
  const ref = useRef<HTMLDivElement>(null);
  const mountTime = useRef(Date.now());
  const {
    spinFrequency,
    colourSpinFrequency,
    radius,
    gapFraction,
    thickness,
    fadeIn,
    fadeInDuration,
    fadeInDuration2,
  } = {
    ...loadingDefaults,
    ...props,
  };

  // manually set the circle radius: safari doesn't support changing the r attribute via css
  useEffect(() => {
    ref.current?.firstElementChild?.firstElementChild?.setAttribute('r', `${radius - thickness / 2}px`);
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: fadeIn ? 0 : 1,
        animation: fadeIn ? `fadeIn ${fadeInDuration}s ${fadeInDuration2}s ease-in-out forwards` : undefined,
      }}
    >
      <NemoSpinner
        $thickness={thickness}
        $radius={radius}
        $spinFrequency={spinFrequency}
        $colourSpinFrequency={colourSpinFrequency}
        $gapFraction={gapFraction}
        $time={mountTime.current}
      />
    </div>
  );
}

export function NemoLoadingCentered(props: Partial<NemoLoadingProps> = {}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <NemoLoading {...props} />
    </div>
  );
}
